const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6951278",
    space_js: "//qfzpybo.szqzytech.com/common/q/resource/lkt/openjs/iuam/n.js"
  }
];

const Native = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6951279",
    space_js: "//qfzpybo.szqzytech.com/common/rm/common/lujv/b/n_u.js"
  }
];

const Banner = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6951280",
    space_js: "//qfzpybo.szqzytech.com/common/je/resource/d_mb/static/n/source/tgk.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6951281",
    space_js: "//qfzpybo.szqzytech.com/site/k/source/fen/resource/cou/production/h/o.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6951282",
    space_js: "//qfzpybo.szqzytech.com/production/lgf/o/production/d/pv_iv.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native,
  Home_banner: Banner,
  Detail_inter: Inter,
  Detail_native: Native,
  Detail_banner: Banner,
  List_native: Native,
  List_banner: Banner,
  Result_inter: Inter,
  Result_native: Native,
  Result_banner: Banner,
  Result_native_center1: Native2,
  Result_native_center2: Native3,
};
